.ant-input {
    border-radius: @input-border-radius;
    height: @input-height;
}

.ant-input-number-input {
    height: calc(@input-height - 2px);
}

.ant-input-number-input {
    padding: 4px 10px;
}

//this span wraps the input when errored
.ant-input-affix-wrapper,
.ant-input-number-affix-wrapper {
    border-radius: @input-border-radius; 
    padding: 4px 10px;
    width: 100%;
    .ant-input,
    .ant-input-number,
    .ant-input-number-input {
        border-radius: 0;
        height: 30px;
    }
}

.ant-form-item-label {
    >label {
        text-transform: capitalize;
    }
}

//disabled style
.ant-input[disabled],
.ant-input-affix-wrapper-disabled {
    background-color: @white;
}

// remove inner square on errored inputs
.ant-form-item-has-error :not(.ant-input-disabled).ant-input:focus,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:focus,
.ant-form-item-has-error :not(.ant-input-disabled).ant-input-focused,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper-focused {
    box-shadow: none;
}

//remove the x icon in errored inputs
.ant-input-feedback-icon,
.ant-form-item-feedback-icon {
    display: none;
}

//number only inputs. <InputNumber>
.ant-input-number {
    width: 100%;
    border-radius: @input-border-radius;
}