@import (reference) '~antd/dist/antd.less';

.title-display-mixin {
    font-size: 54px;
    line-height: 67px;
    font-weight: 400;
}
.title-display {
    .title-display-mixin();
}
.title-display-bold {
    .title-display-mixin();
    font-weight: 700;
}



.title-h1-mixin {
    font-size: 44px;
    line-height: 55px;
    font-weight: 400;
}
.title-h1 {
    .title-h1-mixin();
}
.title-h1-bold {
    .title-h1-mixin();
    font-weight: 700;
}



.title-h2-mixin {
    font-size: 36px;
    line-height: 54px;
    font-weight: 400;
}
.title-h2 {
    .title-h2-mixin();
}
.title-h2-bold {
    .title-h2-mixin();
    font-weight: 700;
}



.title-h3-mixin {
    font-size: 32px;
    line-height: 40px;
    font-weight: 400;
}
.title-h3 {
    .title-h3-mixin();
}
.title-h3-bold {
    .title-h3-mixin();
    font-weight: 700;
}



.title-h4-mixin {
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;
}
.title-h4 {
    .title-h4-mixin();
}
.title-h4-bold {
    .title-h4-mixin();
    font-weight: 700;
}



.p-large-mixin {
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
}
.p-large {
    .p-large-mixin();
}
.p-large-bold {
    .p-large-mixin();
    font-weight: 700;
}



.p-medium-mixin {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}
.p-medium {
    .p-medium-mixin();
}
.p-medium-bold {
    .p-medium-mixin();
    font-weight: 700;
}



.p-small-mixin {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
}
.p-small {
    .p-small-mixin();
}
.p-small-bold {
    .p-small-mixin();
    font-weight: 700;
}



.caption-medium-mixin {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
}
.caption-medium {
    .caption-medium-mixin();
}
.caption-medium-bold {
    .caption-medium-mixin();
    font-weight: 700;
}



.caption-small-mixin {
    font-size: 11px;
    line-height: 16px;
    font-weight: 400;
}
.caption-small {
    .caption-small-mixin();
}
.caption-small-bold {
    .caption-small-mixin();
    font-weight: 700;
}




//font colors
.element-light-primary {
    color: @element-light-primary;
}
.element-light-secondary {
    color: @element-light-secondary;
}
.element-light-tertiary {
    color: @element-light-tertiary;
}
//--------------------

.element-dark-primary {
    color: @element-dark-primary;
}
.element-dark-secondary {
    color: @element-dark-secondary;
}
.element-dark-tertiary {
    color: @element-dark-tertiary;
}
//--------------------
.element-main-primary {
    color: @element-main-primary;
}
.element-main-secondary { 
    color: @element-main-secondary;
}
//--------------------
.element-accent-primary {
    color: @element-accent-primary;
}
.element-accent-secondary {
    color: @element-accent-secondary;
}
//--------------------
.element-success-primary {
    color: @element-success-primary;
}
.element-success-secondary {
    color: @element-success-secondary;
}
//--------------------
.element-warning-primary {
    color: @element-warning-primary;
}
.element-warning-secondary {
    color: @element-warning-secondary;
}
//--------------------
.element-error-primary {
    color: @element-error-primary;
}
.element-error-secondary {
    color: @element-error-secondary;
}

//--------------------
.accent-primary {
    color: @accent-primary-color
}

//--------------------
.main-primary {
    color: @main-primary-color
}