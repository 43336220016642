.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error .ant-input-prefix {
  color: #ff4d4f;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-error .ant-input-prefix {
  color: #ff4d4f;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-error .ant-input-number-prefix {
  color: #ff4d4f;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-prefix {
  color: #ff4d4f;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.Button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
}
.Button::before {
  content: '';
}
.Button.type-primary {
  width: 200px;
  border: 0;
  border-radius: 50px;
  background-color: #D58047;
  color: #FBF3ED;
}
.Button.type-primary.disabled-false:hover {
  background-color: #DD996C;
}
.Button.type-primary.disabled-false:active {
  background-color: #A06035;
}
.Button.type-primary.disabled-true {
  background-color: #F6F8F8;
  color: #CCD6D9;
}
.Button.type-secondary {
  width: 200px;
  border: 2px solid #CCD6D9;
  border-radius: 50px;
  background-color: #CCD6D9;
  color: #2B2F30;
}
.Button.type-secondary.disabled-false:hover {
  background-color: #BBC8CD;
  border-color: #BBC8CD;
}
.Button.type-secondary.disabled-false:active {
  background-color: #EDF0F2;
}
.Button.type-secondary.disabled-true {
  background-color: #F6F8F8;
  border-color: #F6F8F8;
  color: #CCD6D9;
}
.Button.type-soft {
  width: 200px;
  border: 2px solid #F7E6DA;
  border-radius: 50px;
  background-color: #F7E6DA;
  color: #A06035;
}
.Button.type-soft.disabled-false:hover {
  background-color: #FBF3ED;
  border-color: #FBF3ED;
}
.Button.type-soft.disabled-false:active {
  background-color: #F7E6DA;
  border-color: #F7E6DA;
}
.Button.type-soft.disabled-true {
  background-color: #F6F8F8;
  border-color: #F6F8F8;
  color: #CCD6D9;
}
.Button.type-link {
  width: 200px;
  border: 2px solid transparent;
  border-radius: 50px;
  background-color: transparent;
  color: #D58047;
}
.Button.type-link.disabled-false:hover {
  background-color: #F7E6DA;
}
.Button.type-link.disabled-false:active {
  background-color: #FBF3ED;
}
.Button.type-link.disabled-true {
  background-color: #F6F8F8;
  border-color: #F6F8F8;
  color: #CCD6D9;
}
.Button:active.disabled-false {
  transition: none;
}
.Button:active.disabled-false .icon-container {
  transition: none;
}
.Button .icon-container {
  display: flex;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}
.Button .icon-container .anticon svg {
  width: 16px;
  height: 16px;
}
.Button .icon-container.leftIcon {
  margin-left: -4px;
  margin-right: 8px;
}
.Button .icon-container.rightIcon {
  margin-left: 8px;
  margin-right: -4px;
}
.Button.width-hugged {
  width: fit-content;
  padding: 0 18px;
}
.Button.width-auto {
  width: auto;
}
.Button.width-full {
  width: 100%;
}
.Button.disabled-false {
  cursor: pointer;
}
.Button.disabled-true {
  cursor: default;
}
.Button.size-small {
  height: 32px;
}
.Button.size-small .icon-container {
  width: 16px;
  height: 16px;
}
.Button.size-small .icon-container .anticon svg {
  width: 16px;
  height: 16px;
}
.Button.size-medium {
  height: 40px;
}
.Button.size-medium .icon-container {
  width: 20px;
  height: 20px;
}
.Button.size-medium .icon-container .anticon svg {
  width: 20px;
  height: 20px;
}
.Button.size-large {
  height: 48px;
}
.Button.size-large .icon-container {
  width: 24px;
  height: 24px;
}
.Button.size-large .icon-container .anticon svg {
  width: 24px;
  height: 24px;
}
.Button.onlyIcon-true {
  padding: 0;
}
.Button.onlyIcon-true.size-small {
  width: 32px;
  height: 32px;
}
.Button.onlyIcon-true.size-medium {
  width: 36px;
  height: 36px;
}
.Button.onlyIcon-true.size-large {
  width: 40px;
  height: 40px;
}
.Button.onlyIcon-true .icon-container {
  margin: 0;
}
