
.Shadow-50-mixin {
    box-shadow: 0px 1px 2px rgba(28, 39, 49, 0.08);
}

.Shadow-50 {
    .Shadow-50-mixin();
}

.Shadow-100-mixin {
    box-shadow: 0px 2px 6px rgba(28, 39, 49, 0.08);
}

.Shadow-100 {
    .Shadow-100-mixin();
}

.Shadow-200-mixin {
    box-shadow: 0px 6px 12px rgba(28, 39, 49, 0.05);
}

.Shadow-200 {
    .Shadow-200-mixin();
}

.Shadow-300-mixin {
    box-shadow: 0px 17px 33px -2px rgba(28, 39, 49, 0.05);
}

.Shadow-300 {
    .Shadow-300-mixin();
}

.Shadow-400-mixin {
    box-shadow: 0px 25px 40px -10px rgba(28, 39, 49, 0.08);
}

.Shadow-400 {
    .Shadow-400-mixin();
}

.Shadow-500-mixin {
    box-shadow: 0px 25px 60px -10px rgba(28, 39, 49, 0.12);
}

.Shadow-500 {
    .Shadow-500-mixin();
}
