.d-flex {
    display: flex;
}

.d-flex-col {
    display: flex;
    flex-direction: column;
}

.d-flex-centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.d-flex-align {
    display: flex;
    align-items: center;
}

.d-flex-justify {
    display: flex;
    justify-content: center;
}

.capitalize {
    text-transform: capitalize;
}

.uppercase {
    text-transform: uppercase;
}

.lowercase {
    text-transform: lowercase;
}

.d-none {
    display: none;
}

.sortable-object {
    z-index: 2000;
}

.t-align-center {
    text-align: center;
}

.cursor-pointer {
    &:hover {
        cursor: pointer;
    }
}

//paddings
.pl-40 {
    padding-left: 40px;
}

.pt-40 {
    padding-top: 40px;
}


//margins 
.ml-auto {
    margin-left: auto;
}

.ml-5 {
    margin-left: 5px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-20 {
    margin-left: 20px;
}

.ml-40 {
    margin-left: 40px;
}

.mr-auto {
    margin-right: auto;
}

.mr-5 {
    margin-right: 5px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-20 {
    margin-right: 20px;
}

.mr-40 {
    margin-right: 40px;
}

.mt-5 {
    margin-top: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mb-5 {
    margin-bottom: 5px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}