@import (reference) 'src/Style/dropshadows.less';
.ant-table {
    padding: 0 10px; //make the white backround around table
    background-color:  @white;
    border-radius: 8px;
    .Shadow-50();

    table {
        border-spacing: 0 10px; //make the spacing between rows
    }

    .ant-table-container table > thead > tr:first-child th:first-child {
        border-radius: 8px 0 0 8px; //make the header rounded
        border-left: 1px solid @surfaces-secondary; //only first child has left border
    }
    .ant-table-container table > thead > tr:first-child th:last-child {
        border-radius: 0 8px 8px 0; //make the header rounded
        border-right: 1px solid @surfaces-secondary; //only last child has right border
    }

    .ant-table-container table > tbody td:first-child {
        border-radius: 8px 0 0 8px; //make rows rounded
        border-left: 1px solid @surfaces-tertiary; //only first child has left border
    }
    .ant-table-container table > tbody td:last-child {
        border-radius: 0 8px 8px 0; //make rows rounded
        border-right: 1px solid @surfaces-tertiary; //only last child has right border
    }

    //header inner padding
    .ant-table-thead  {
      .ant-table-cell {
        padding: 10px;
      }
    }

    //header border
    table > thead > tr > th {
        //only set top-bottom, left right is defined higher
        border-top: 1px solid @surfaces-secondary;
        border-bottom: 1px solid @surfaces-secondary;
    }

    .ant-table-column-title {
        // column headers typography
        .p-medium;
        .element-light-secondary;
        text-transform: capitalize;
    }

    th.ant-table-cell {
      // column headers typography for non sortable columns
      .p-medium;
      .element-light-secondary;
      text-transform: capitalize;
  }

    .ant-table-row {
      // row typography
      .ant-table-cell {
        .p-medium;
        .element-light-primary;
      }
    }

    .ant-table-tbody > tr > td {
        // row styles
        background-color: @white;
        //only set top-bottom, left right is defined higher
        border-top: 1px solid @surfaces-tertiary;
        border-bottom: 1px solid @surfaces-tertiary;
        padding: 5px 10px;
        height: 54px; //This works as a min-height because tables..
    }

    //overrides for special cases defined in columnType of component
    .col-border-right {
        border-right: 1px solid @surfaces-tertiary;
    }
    .col-border-left {
        border-left: 1px solid @surfaces-tertiary;
    }
    thead {
        .col-border-right {
            border-right: 1px solid @surfaces-secondary;
        }
        .col-border-left {
            border-left: 1px solid @surfaces-secondary;
        }
    }
    
    .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
        //hover on row
        background-color: @surfaces-disabled;
        
    }
}

.ant-table-wrapper {
  //clickable row have cursor on hover
  &.clickable {
    .ant-table-row:hover {
      cursor: pointer;
    }
  }
}

//pagination style
.ant-pagination {
    .ant-pagination-item {
      font-weight: bold;
      min-width: 40px;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      border-radius: 5px;

      a {
        color: @element-light-primary;
      }

      &:hover {
        a {
            color: @accent-primary-color;
         }
      }
  
      &.ant-pagination-item-active {
        background-color: @accent-primary-color;
  
        a {
          color: @element-accent-primary;
        }
      }
    }
  
    .ant-pagination-prev,
    .ant-pagination-next {
      min-width: 40px;
      width: 40px;
      height: 40px;
  
      button {
        border: none;
        border-radius: 4px;
      }
  
      .anticon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  
    .ant-pagination-options {
      height: 40px;
  
      .ant-select-selector {
        border: none;
        border-radius: 4px;
      }
  
      .ant-pagination-options-size-changer,
      .ant-select-selector {
        height: 100%;
  
        .ant-select-selection-item {
          line-height: 3;
        }
      }
    }
}

//wierd line between head columns when sortable
.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
}