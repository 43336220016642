.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error .ant-input-prefix {
  color: #ff4d4f;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-error .ant-input-prefix {
  color: #ff4d4f;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-error .ant-input-number-prefix {
  color: #ff4d4f;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-prefix {
  color: #ff4d4f;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.SingleSelect {
  min-width: 120px;
  /* --- input styles --- */
  /* --- input styles --- */
  /* --- the selected element in the dropdown --- */
  /* --- the element selected in the dropdown --- */
  /* --- dropdown indicator on the right  --- */
  /* --- dropdown indicator on the right  --- */
}
.SingleSelect :hover {
  cursor: pointer;
}
.SingleSelect div[class*="control"] {
  border-radius: 0;
  border: 1px solid #CCD6D9;
  box-shadow: none;
  min-height: 40px;
  border-radius: 4px;
  transition: 300ms all;
}
.SingleSelect div[class*="control"] > div {
  padding-left: 10px;
}
.SingleSelect div[class*="control"] > div div[class*="placeholder"] {
  font-family: Nunito;
  color: #808C90;
  margin-left: 10px;
}
.SingleSelect div[class*="control"]:hover {
  border-color: #e3a16f !important;
}
.SingleSelect.round div[class*="control"] {
  border-radius: 60px;
}
.SingleSelect.disabled div[class*="control"] {
  background-color: #ffffff;
}
.SingleSelect.disabled div[class*="control"] .dropdown-selected-element .dropdown-option-label {
  color: #CCD6D9;
}
.SingleSelect.disabled div[class*="control"] div[class*='indicatorContainer'] {
  color: #CCD6D9;
}
.SingleSelect.borderless > div[class*="control"] {
  border-color: transparent;
}
.SingleSelect .dropdown-selected-element {
  width: 100%;
  display: flex;
  align-items: center;
}
.SingleSelect .dropdown-selected-element > div {
  max-width: 100%;
}
.SingleSelect .dropdown-selected-element .selected-option-details {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.SingleSelect div[class*='indicatorContainer'] {
  margin-right: 5px;
  color: #2B2F30;
  padding-left: 0;
  max-height: 40px;
}
.SingleSelect div[class*='indicatorContainer'] .ant-spin {
  height: 20px;
}
/* --- menu styles */
div[class*="menu"] {
  z-index: 2;
}
div[class*="menu"] .single-dropdown-menu-option-container {
  width: 100%;
}
div[class*="menu"] .single-dropdown-menu-option-container .menuOptionDetails {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
div[class*="menu"] .single-dropdown-menu-option-container .menuOptionDetails .left-icon {
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-left: 5px;
}
div[class*="menu"] .single-dropdown-menu-option-container .menuOptionDetails .dropdown-menu-option-label {
  display: flex;
  align-items: center;
}
div[class*="menu"] .single-dropdown-menu-option-container .menuOptionDetails .dropdown-option-img {
  background-position: center;
  border-radius: 50%;
  margin-right: 15px;
  min-width: 24px;
  height: 24px;
  text-align: center;
  display: inline-flex;
  color: #ffffff;
  justify-content: center;
  align-items: center;
}
div[class*="menu"] .single-dropdown-menu-option-container .menuOptionDetails .selected-checkmark {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 6px;
}
div[class*="menu"] .single-dropdown-menu-option-container .menuOptionDetails .selected-checkmark svg {
  width: 20px;
  height: 20px;
}
/* --- menu styles */
.ValidatedFormItem .ant-form-item-has-error div[class*="control"]:hover {
  border-color: #D7382D !important;
}
