/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: @layout-body-background;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: @surfaces-primary;
    border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: @surfaces-secondary;
}

/* Handle on click */
::-webkit-scrollbar-thumb:active {
    background: @surfaces-primary;
}